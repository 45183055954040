import React, { useRef, useState, useEffect } from 'react';
import '../styles/Landing.css';
import { PageTitle, PageDivider, Pill } from '../components/Components'
const Landing = () =>
{
    const titleRef = useRef(null);
    const [dividerWidth, setDividerWidth] = useState('0');

    useEffect(() =>
    {
        if (titleRef.current)
        {
            const titleWidth = titleRef.current.offsetWidth;
            const additionalWidth = window.innerWidth * 0.06; // 2vw
            setDividerWidth(`${titleWidth + additionalWidth}px`);
        }
    }, []);

    function scrollToDiv(id)
    {
        const element = document.getElementById(id);
        if (element)
        {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <div className='page-background' style={{ height: "92vh" }}>
            <div style={{ marginTop: "-16vh" }} className="page-content">
                <PageTitle ref={titleRef}>Jacob Schwarzenberger</PageTitle>
                <PageDivider width={dividerWidth} ></PageDivider>
                <div className="page-small-text">Freelance Web Developer</div>
                <div className="pill-container">
                    <Pill func={() => scrollToDiv('my-stack-anchor')} className="pillClickable">My Stack</Pill>
                    <Pill func={() => scrollToDiv('contact-me-anchor')} className="pillClickable">Contact Me</Pill>
                    <Pill func={() => scrollToDiv('about-me-anchor')} className="pillClickable">About Me</Pill>
                </div>
            </div>
        </div>
    );
}
export default Landing;