import React from 'react';

const NavButton = ({ src, alt, href, reportActivity }) =>
{
    const handleClick = () =>
    {
        reportActivity({
            type: 'Button Clicked',
            name: alt
        });
    };

    return (
        <a className="nav-button" rel="noopener noreferrer" target="_blank" href={href} onClick={handleClick}>
            <img className="nav-button-img" src={src} alt={alt} />
        </a>
    );
}
export default NavButton;