import React, { useRef, useState, useEffect } from 'react';
import '../styles/ContactMe.css';
import { PageTitle, PageDivider, Pill } from '../components/Components'
import { ToastContainer, CustomToast } from '../components/ToastContainer'


const ContactMe = ({ id, reportActivity }) =>
{
    const titleRef = useRef(null);
    const [dividerWidth, setDividerWidth] = useState('0');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [toasts, setToasts] = useState([<CustomToast></CustomToast>]);

    useEffect(() =>
    {
        if (titleRef.current)
        {
            const titleWidth = titleRef.current.offsetWidth;
            const additionalWidth = window.innerWidth * 0.06; // 2vw
            setDividerWidth(`${titleWidth + additionalWidth}px`);
        }
    }, []);
    async function setEmailData(jsonData)
    {
        const url = 'https://0ofzpp4rke.execute-api.us-west-1.amazonaws.com/dev/Messages';
        const requestOptions = {
            method: 'POST',
            headers:
            {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(jsonData, null, 2),
            redirect: "follow"
        };

        let resp = await fetch(url, requestOptions)

        return (resp['status'] == '200')
    }

    async function sendEmail()
    {
        let data = { "name": name, "email": email, "message": message }

        let conditions = name.length > 0 && email.length > 0 && message.length > 0
        let newToast = null
        let resp = false

        if (conditions)
        {
            resp = await setEmailData(data)
        }

        if (resp)
        {
            newToast = <CustomToast text={"Email Sent!"}></CustomToast>
        }
        else
        {
            newToast = <CustomToast className="failed-toast" text={"Email Failed."}></CustomToast>
        }
        reportActivity({
            type: 'Button Clicked',
            name: "Send email"
        });
        setToasts([newToast]);
    }

    return (
        <div className='page-background' >
            <div id={id} className="page-content">
                <PageTitle ref={titleRef}>Contact Me</PageTitle>
                <PageDivider width={dividerWidth} ></PageDivider>
                <div className="contact-form">
                    <ToastContainer>{toasts}</ToastContainer>
                    <input
                        className="name-field"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <input
                        className="email-field"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <textarea
                        className="message-field"
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    <Pill func={sendEmail} style={{ cursor: 'pointer' }}>Send</Pill>
                </div>
            </div>
        </div>
    );
}
export default ContactMe;