import React, { useRef, useState, useEffect } from 'react';
import '../styles/Footer.css';
import { PageDivider } from './Components';
import resumeImage from '../assets/reusme.png'
import gitHubImage from '../assets/github.png'
import linkedinImage from '../assets/linkedin.png'
import resumePdf from '../assets/Resume_New.pdf'
import NavLink from './NavLink';
import NavButton from './NavButton';

const Footer = () =>
{
    const titleRef = useRef(null);
    const [dividerWidth, setDividerWidth] = useState('0');

    useEffect(() =>
    {
        if (titleRef.current)
        {
            const titleWidth = titleRef.current.offsetWidth;
            const additionalWidth = window.innerWidth * 0.20; // 2vw
            setDividerWidth(`${titleWidth + additionalWidth}px`);
        }
    }, []);

    function scrollToDiv(id)
    {
        const element = document.getElementById(id);
        if (element)
        {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <div className="footer">
            <div ref={titleRef} className="footer-links">
                <NavLink func={() => scrollToDiv('home-anchor')}>
                    Home
                </NavLink>

                <div className='dot-crumb'></div>
                <NavLink func={() => scrollToDiv('my-stack-anchor')}>
                    Stack
                </NavLink>

                <div className='dot-crumb'></div>
                <NavLink func={() => scrollToDiv('contact-me-anchor')}>
                    Contact
                </NavLink>
                <div className='dot-crumb'></div>
                <NavLink func={() => scrollToDiv('about-me-anchor')}>
                    About
                </NavLink>
            </div>
            <PageDivider width={dividerWidth}></PageDivider>
            <div className="footer-text">
                Jacob Schwarzenberger<br />
                jjschwar@uci.edu<br />
                Dublin, CA<br />
            </div>
            <div className='footer-button-container'>
                <NavButton src={resumeImage} alt="Resume" href={resumePdf}></NavButton>
                <NavButton src={gitHubImage} alt="Github" href="https://github.com/jacobs0925"></NavButton>
                <NavButton src={linkedinImage} alt="Linkedin" href="https://www.linkedin.com/in/jjschwar/"></NavButton>
            </div>
        </div>
    );
}
export default Footer;