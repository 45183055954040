import React, { useRef, useState, useEffect } from 'react';
import '../styles/MyStack.css';
import { PageTitle, PageDivider, Pill } from '../components/Components'
import { StackPillContainer } from '../components/StackPillContainer'
import { Project } from '../components/Project'
import projects from './Projects'

const MyStack = ({ id, reportActivity }) =>
{
    const titleRef = useRef(null);
    const [dividerWidth, setDividerWidth] = useState('0');
    const [selectedTech, setSelectedTech] = useState({});

    useEffect(() =>
    {
        if (titleRef.current)
        {
            const titleWidth = titleRef.current.offsetWidth;
            const additionalWidth = window.innerWidth * 0.04; // 2vw
            setDividerWidth(`${titleWidth + additionalWidth}px`);
        }
    }, []);

    const handleTechClick = (tech) =>
    {
        setSelectedTech(prevState => ({
            ...prevState,
            [tech]: !prevState[tech]
        }));
    };

    let frontendPills = ['HTML', 'CSS', 'Javascript', 'Bootstrap', 'React','AJAX']
    let backendPills = ['Python', 'Firebase', 'Webpack', 'Java', 'AWS', 'Express', 'SQL', 'Node.js']

    let reactProjects = []

    let anySelected = false
    for (let tech in selectedTech)
    {
        if (selectedTech[tech])
        {
            anySelected = true
            break
        }
    }
    if (anySelected)
    {
        for (let project of projects)
        {
            for (let tech in selectedTech)
            {
                if (!selectedTech[tech])
                {
                    continue
                }

                if (!project.stackLinks.includes(tech))
                {
                    continue
                }

                reactProjects.push(<Project reportActivity={reportActivity} selectedTech={selectedTech} projData={project}></Project>)
                break
            }
        }
    }
    else
    {
        for (let project of projects)
        {
            reactProjects.push(<Project reportActivity={reportActivity} selectedTech={selectedTech} projData={project}></Project>)
        }
    }
    return (
        <div className='page-background'>
            <div id={id} className="page-content">
                <PageTitle ref={titleRef}>My Stack</PageTitle>
                <PageDivider width={dividerWidth} ></PageDivider>
                <div className="stack-pill-container-holder">
                    <StackPillContainer header={"Frontend"}>
                        {frontendPills.map((item) => (
                            <Pill func={() => handleTechClick(item)} key={item} className={"pillClickable " + (selectedTech[item] == true ? "selected-pill" : "")}>{item}</Pill>
                        ))}
                    </StackPillContainer>

                    <StackPillContainer header={"Backend"}>
                        {backendPills.map((item) => (
                            <Pill func={() => handleTechClick(item)} key={item} className={"pillClickable " + (selectedTech[item] == true ? "selected-pill" : "")}>{item}</Pill>
                        ))}
                    </StackPillContainer>
                </div>
                <div className="page-small-text">
                    Try clicking on tools to see some of my relevant projects!
                </div>
                <div className="project-grid">
                    {reactProjects}
                </div>
            </div>
        </div>
    );
}
export default MyStack;