import React from 'react';
import '../styles/NavLink.css';

const NavLink = ({ children, func }) =>
{
    return (
        <div onClick={func} className="nav-link bounce">
            {children}
        </div>
    );
}
export default NavLink;