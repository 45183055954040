import React from "react";

const StackPillContainer = ({ children, header }) =>
{
    return (
        <div className="stack-pill-container">
            <div className="pill-container-header">
                {header}
            </div>
            <div className="stack-pill-holder">
                {children}
            </div>
        </div>
    );
}
export { StackPillContainer };