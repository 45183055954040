import React, { useState, useEffect } from 'react';
import '../styles/Project.css';
import { Pill } from './Components';

const Project = ({ selectedTech, projData, reportActivity }) =>
{
    const { stackLinks, mediaLink, title, projectLink, githubLink } = projData;
    const [isSelected, setIsSelected] = useState(false);
    const [imageOrientation, setImageOrientation] = useState(null);

    useEffect(() =>
    {
        const img = new Image();
        img.src = mediaLink;
        img.onload = () =>
        {
            const width = img.width;
            const height = img.height;
            if (width > height)
            {
                setImageOrientation('wide');
            } else
            {
                setImageOrientation('tall');
            }
        };
    }, [mediaLink]);

    const toggleSelected = () =>
    {
        //on to off
        if (isSelected)
        {
            reportActivity({
                type: 'Project Closed',
                name: title
            });
        }
        //off to on
        else
        {
            reportActivity({
                type: 'Project Opened',
                name: title
            });
        }
        setIsSelected(!isSelected);
    };

    // Toggle selected class based on isSelected state
    const containerClassName = `project-container ${isSelected ? 'project-container-selected' : ''}`;

    // Dynamic class for .project-text-and-image based on image orientation
    const textAndImageClassName = `project-text-and-image ${imageOrientation === 'wide' ? 'wide-image' : 'tall-image'}`;

    return (
        <div className="project">
            <div className={containerClassName} onClick={toggleSelected}>
                <div className="selected-header">{title}</div>
                <div className={textAndImageClassName}>
                    <img className="project-image" alt="Project" src={mediaLink} />
                    {projData.summary}
                </div>
                <div className='project-nav-pill-container'>
                    <Pill key={'gitlink'} className="minipill navpill pillClickable" style={{ display: projectLink !== '' ? 'flex' : 'none' }} func={(e) =>
                    {
                        e.stopPropagation();
                        window.open(projectLink, '_blank');
                    }}> Visit Site</Pill>
                    <Pill key={'projlink'} className="minipill navpill pillClickable" style={{ display: githubLink !== '' ? 'flex' : 'none' }} func={(e) =>
                    {
                        e.stopPropagation();
                        window.open(githubLink, '_blank');
                    }}> Github </Pill>
                </div>
                <div className="project-pill-container">
                    {stackLinks.map((tech) => (
                        <Pill
                            key={tech}
                            className={`minipill ${selectedTech[tech] ? 'selected-pill' : ''}`}
                        >
                            {tech}
                        </Pill>
                    ))}

                </div>
            </div>
            <div className="project-title">{title}</div>
        </div>
    );
};

export { Project };
