import './styles/App.css';
import NavBar from './components/NavBar';
import Landing from './components/Landing';
import MyStack from './components/MyStack'
import ContactMe from './components/ContactMe'
import Breadcrumbs from './components/Breadcrumbs'
import AboutMe from './components/AboutMe'
import Footer from './components/Footer'
import { useState, useEffect } from 'react';

let crumbs = {
    0: { id: "home-anchor", text: "Home" },
    1: { id: "my-stack-anchor", text: "Stack" },
    2: { id: "contact-me-anchor", text: "Contact" },
    3: { id: "about-me-anchor", text: "About" }
}

function App()
{
    const [activityLog, setActivityLog] = useState({
        RegionTimings: [],
        ProjectTimings: [],
        ButtonsPressed: [],
        id: 'anonymous' + generateRandomString(8)
    });
    const [activeTimer, setActiveTimer] = useState(null);
    const [projectTimer, setProjectTimer] = useState(null);
    const [initialized, setInitialized] = useState(false);

    function generateRandomString(length)
    {
        const alphanumericChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++)
        {
            const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
            result += alphanumericChars[randomIndex];
        }
        return result;
    }

    async function submitActivityLog(jsonData)
    {
        const url = 'https://0ofzpp4rke.execute-api.us-west-1.amazonaws.com/dev/Logs';
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(jsonData, null, 2),
            redirect: "follow"
        };

        let resp = await fetch(url, requestOptions)
        return (resp['status'] == '200')
    }

    const cleanup = () =>
    {
        const endTime = Date.now();
        const elapsed = endTime - activeTimer;

        const finalLog = {
            ...activityLog,
            RegionTimings: [
                ...activityLog.RegionTimings,
                { type: 'Region Exited', name: 'Home', duration: Math.floor(elapsed / 1000) }
            ]
        };
        if (!activityLog.id.includes('anonymous'))
        {
            submitActivityLog(finalLog);
        }
    };

    useEffect(() =>
    {
        if (!initialized)
        {
            const hash = window.location.hash.substring(1);
            const initialLog = {
                RegionTimings: [{ type: 'Region Entered', name: 'Home' }],
                ProjectTimings: [],
                ButtonsPressed: [],
                id: hash || 'anonymous' + generateRandomString(8)
            };
            setActivityLog(initialLog);
            setActiveTimer(Date.now());
            setInitialized(true);
            submitActivityLog(initialLog);
        }

        window.addEventListener('beforeunload', cleanup);

        return () =>
        {
            window.removeEventListener('beforeunload', cleanup);
            cleanup();
        };
    }, [initialized, activityLog, activeTimer]);

    const reportActivity = (data) =>
    {
        if (data.type === 'Region Entered')
        {
            setActiveTimer(Date.now());
            setActivityLog(prevLog => ({
                ...prevLog,
                RegionTimings: [
                    ...prevLog.RegionTimings,
                    { type: 'Region Entered', name: data.name }
                ]
            }));
        } else if (data.type === 'Region Exited')
        {
            const endTime = Date.now();
            const elapsed = endTime - activeTimer;
            setActivityLog(prevLog => ({
                ...prevLog,
                RegionTimings: [
                    ...prevLog.RegionTimings,
                    { type: 'Region Exited', name: data.name, duration: Math.floor(elapsed / 1000) }
                ]
            }));
        } else if (data.type === 'Project Opened')
        {
            setProjectTimer(Date.now());
            setActivityLog(prevLog => ({
                ...prevLog,
                ProjectTimings: [
                    ...prevLog.ProjectTimings,
                    { type: 'Project Opened', name: data.name }
                ]
            }));
        } else if (data.type === 'Project Closed')
        {
            const endTime = Date.now();
            const elapsed = endTime - projectTimer;
            setActivityLog(prevLog => ({
                ...prevLog,
                ProjectTimings: [
                    ...prevLog.ProjectTimings,
                    { type: 'Project Closed', name: data.name, duration: Math.floor(elapsed / 1000) }
                ]
            }));
        } else if (data.type === 'Button Clicked')
        {
            setActivityLog(prevLog => ({
                ...prevLog,
                ButtonsPressed: [
                    ...prevLog.ButtonsPressed,
                    { type: 'Button Clicked', name: data.name }
                ]
            }));
        }
    };

    return (
        <div className="App">
            <NavBar reportActivity={reportActivity} anchorId="home-anchor" />
            <Breadcrumbs reportActivity={reportActivity} crumbIDs={crumbs} />
            <Landing />
            <MyStack reportActivity={reportActivity} id="my-stack-anchor" />
            <ContactMe reportActivity={reportActivity} id="contact-me-anchor" />
            <AboutMe id="about-me-anchor" />
            <Footer />
        </div>
    );
}

export default App;