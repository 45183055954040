import React, { useRef, useState, useEffect } from 'react';
import { PageTitle, PageDivider, Pill } from '../components/Components'
import '../styles/AboutMe.css';
import graduationImg from '../assets/graduation.png'

const AboutMe = ({ id }) =>
{
    const titleRef = useRef(null);
    const [dividerWidth, setDividerWidth] = useState('0');

    useEffect(() =>
    {
        if (titleRef.current)
        {
            const titleWidth = titleRef.current.offsetWidth;
            const additionalWidth = window.innerWidth * 0.04; // 2vw
            setDividerWidth(`${titleWidth + additionalWidth}px`);
        }
    }, []);

    return (
        <div className='page-background'>
            <div id={id} className="page-content">
                <PageTitle ref={titleRef}>About Me</PageTitle>
                <PageDivider width={dividerWidth} ></PageDivider>
                <div className='about-me-container'>
                    <img className="about-me-img" alt='Graduation' src={graduationImg}></img>
                    <div className="about-me-text">
                        Hello! My name is Jacob Schwarzenberger and I’m a freelance web developer. I graduated in June 23’ from UC Irvine with a B.S. in Computer Science and Engineering. Since then I’ve been doing freelance work to gain experience while applying for jobs.<br /><br />I’m a very self driven person and I love learning. I try to grow each day and am always incorporating new technologies in my projects to build up my tech stack. <br /><br />When I’m not programming I enjoy lifting weights, hanging out with my girlfriend, Boxing and Muay Thai!
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AboutMe;