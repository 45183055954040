import React, { forwardRef } from 'react';

const PageTitle = forwardRef(({ children }, ref) =>
{
    return (
        <div ref={ref} className="page-title">
            {children}
        </div>
    );
});

const PageDivider = ({ width }) =>
{
    return (
        <div style={{ width }} className='divider'>

        </div>
    );
}

const Pill = ({ className, children, style, func }) =>
{
    return (
        <div onClick={func} style={style} className={className ? 'pill ' + className : 'pill'}>
            {children}
        </div>
    );
}
export { PageTitle, PageDivider, Pill };