import React from 'react';
import NavButton from './NavButton';
import NavLink from './NavLink';
import resumeImage from '../assets/reusme.png'
import gitHubImage from '../assets/github.png'
import linkedinImage from '../assets/linkedin.png'
import resumePdf from '../assets/Resume_Long.pdf'

function Header({ anchorId, reportActivity })
{

    function scrollToDiv(id)
    {
        const element = document.getElementById(id);
        if (element)
        {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <div className='nav-bar' id={anchorId}>
            <div className='nav-link-container'>
                <NavLink func={() => scrollToDiv('my-stack-anchor')}>
                    My Stack
                </NavLink>
                <NavLink func={() => scrollToDiv('contact-me-anchor')}>
                    Contact Me
                </NavLink>
                <NavLink func={() => scrollToDiv('about-me-anchor')}>
                    About Me
                </NavLink>
            </div>
            <div className='nav-button-container'>
                <NavButton reportActivity={reportActivity} src={resumeImage} alt="Resume" href={resumePdf}></NavButton>
                <NavButton reportActivity={reportActivity} src={gitHubImage} alt="Github" href="https://github.com/jacobs0925"></NavButton>
                <NavButton reportActivity={reportActivity} src={linkedinImage} alt="Linkedin" href="https://www.linkedin.com/in/jjschwar/"></NavButton>
            </div>
        </div>
    );
}

export default Header;