import React, { useRef, useState, useEffect } from 'react';
import '../styles/Breadcrumbs.css';

const BreadCrumbs = ({ crumbIDs, reportActivity }) =>
{
    const [closestCrumbID, setClosestCrumbID] = useState('home-anchor');
    const [initialized, setInitialized] = useState(false);

    useEffect(() =>
    {
        if (!initialized)
        {
            reportActivity({
                type: 'Region Entered',
                name: 'Home'
            });
            setInitialized(true);
        }

        const handleScroll = () =>
        {
            let closestDivID = null;
            let closestDistance = 99999;
            let currentText = ''
            // Convert crumbIDs object to array using Object.values()
            const crumbsArray = Object.values(crumbIDs);

            for (let index in crumbsArray)
            {
                let { id, text } = crumbsArray[index];
                const element = document.getElementById(id);
                if (element)
                {
                    const rect = element.getBoundingClientRect();
                    const distanceToTop = Math.abs(rect.top);
                    if (distanceToTop < closestDistance)
                    {
                        currentText = text
                        closestDivID = id;
                        closestDistance = distanceToTop;
                    }
                }
            }
            if (closestDivID != closestCrumbID)
            {
                reportActivity({
                    type: 'Region Exited',
                    name: closestCrumbID
                });
                setClosestCrumbID(closestDivID);
                reportActivity({
                    type: 'Region Entered',
                    name: currentText
                });
            }

        };

        window.addEventListener('scroll', handleScroll);

        return () =>
        {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [crumbIDs, closestCrumbID, initialized, reportActivity]);

    // Convert crumbIDs object to array using Object.values()
    const crumbsArray = Object.values(crumbIDs);

    return (
        <div className="bread-crumbs">
            {crumbsArray.map((crumb, index) => (
                <React.Fragment key={index}>
                    <BreadCrumb crumbID={crumb.id} isActive={crumb.id === closestCrumbID}>
                        {crumb.text}
                    </BreadCrumb>
                    {index !== crumbsArray.length - 1 && <div className='dot-crumb'></div>}
                </React.Fragment>
            ))}
        </div>
    );
};


const BreadCrumb = ({ crumbID, children, isActive }) =>
{
    const crumbRef = useRef(null);

    const scrollToID = () =>
    {
        const element = document.getElementById(crumbID);
        if (element)
        {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div onClick={scrollToID} ref={crumbRef} className={`bread-crumb ${isActive ? 'current-crumb' : ''}`}>
            {children}
        </div>
    );
};
export default BreadCrumbs;