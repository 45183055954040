import React, { useRef, useState, useEffect } from 'react';
import '../styles/ToastContainer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast } from 'bootstrap'
const ToastContainer = ({ children }) =>
{

    return (
        <div className='my-toast-container'>
            {children}
        </div>
    );
}

const CustomToast = ({ className, text }) =>
{
    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function (toastEl)
    {
        return new Toast(toastEl)
    })
    // console.log(color)
    toastList.forEach(toast => toast.show())
    return (
        <div className="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div className={"d-flex " + className}>
                <div className='toast-body'>
                    {text}
                </div>
                <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    );
}
export { ToastContainer, CustomToast };